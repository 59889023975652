/* COLORS */
:root {
    --DEFAULT: #64a19d;
    --DEFAULT_DARKER: #406966;
    --ERROR: #dc3545;
    --BACKGOUND: #CCCCCC;
    --NAV: #e7e7e7;
    --DARK_BACKGROUND: #b9b9b9;
}

/* GENERAL */

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main-content {
    flex: 1;
}

h3 {
    padding-top: 0.9rem;
}

h4 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 700 !important;
    letter-spacing: 0.15rem;
    justify-content: center;
    display: flex;
    padding-bottom: 3rem;
}

h5 {
    padding-top: 2rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 700 !important;
    letter-spacing: 0.15rem;
    justify-content: center;
    display: flex;
}

.title {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding-bottom: 2rem;
    font-size: 3rem;
    font-weight: 700 !important;
    letter-spacing: 0.2rem;
    justify-content: center;
    display: flex;
}

body {
    background-color: var(--BACKGOUND) !important;
}

.btn-primary {
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    background-color: var(--DEFAULT) !important;
    border-color: var(--DEFAULT) !important;
    letter-spacing: 0.1rem;
}

.btn-primary:hover {
    background-color: var(--DEFAULT_DARKER) !important;
    border-color: var(--DEFAULT_DARKER) !important;
}

.btn-danger {
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    letter-spacing: 0.1rem;
}

.form-check-input:checked {
    background-color: var(--DEFAULT) !important;
    border-color: var(--DEFAULT) !important;
}

.offscreen {
    position: absolute;
    overflow: hidden;
}

.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.modal {
    --bs-modal-width: 440px !important;
}

.modal-title {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.save-button {
    margin-top: 2rem;
}

Form {
    margin-top: 0.5rem;
}

.countrySelect {
    padding-bottom: 0.15rem !important;
    padding-top: 0.15rem !important;
    border-radius: 0.375rem !important;
    background: #fff url(./img/down.svg) right 0.75rem center no-repeat !important;
    background-size: 16px 12px !important;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
    margin-top: 0.1rem !important;
    padding-top: 0 !important;
    border-radius: 0.375rem !important;
}

.countrySelect::after {
    content: none !important;
}

.countrySearch {
    padding-bottom: 0 !important;
    border-radius: 0.4rem !important;
    background: #fff;
    width: 15rem;
}

#rfs-btn {
    padding-bottom: 0.15rem;
    padding-top: 0.15rem;
    border-radius: 0.4rem;
}

.administrationSearch {
    width: 15rem !important;
    margin-left: auto !important;
}



/* FOOTER */

@media screen and (max-width: 576px) {
    .footer.fixed-bottom {
        padding-top: 1rem;
        position: unset;
    }
}

.footer {
    padding-bottom: 1rem;
    font-weight: 700;
    color: #6c6c6c;
    right: 1rem;
    bottom: 1rem;
}

/* NAVBAR */

.top-navbar {
    background-color: var(--NAV);
    margin-bottom: 1rem;
}

.navbar-brand {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1.5rem !important;
    font-weight: 700 !important;
    letter-spacing: 0.1rem;
    margin-bottom: -0.3rem;
}

.navbar-brand img {
    margin-bottom: 0.4rem;
}

.navbar-brand div {
    font-size: 0.8rem !important;
    font-weight: 400 !important;
    letter-spacing: 0.1rem;
}

.dropdown-toggle {
    color: var(--DEFAULT) !important;
    font-weight: 700 !important;
}

.dropdown-item:active {
    background-color: var(--DEFAULT) !important;
}

.nav-link {
    font-size: 1.1rem !important;
}

.nav-link:hover {
    text-decoration: underline;
}

/* START SITE */

.home-page {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.8) 45%, #000 100%),
    url(./img/background.jpg) no-repeat center fixed;
    background-size: cover;
    width: 100%;
    min-height: 100vh;
}

.main-title {
    padding-top: 10rem;
    text-align: center;
    font-size: 6.5rem;
    letter-spacing: 0.8rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.03));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.login-btn {
    background-color: var(--DEFAULT) !important;
    border: none !important;
    padding: 1rem !important;
    width: 10rem !important;
    margin: 3rem auto !important;
    letter-spacing: 0.2rem;
    font-size: 90% !important;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.modal-footer {
    justify-content: unset !important;
}

.persist-checkbox {
    margin-top: 0.5rem;
}

.large-checkbox {
    margin-top: 2.5rem !important;
    margin-left: 1rem;
}

.large-checkbox input[type='checkbox'] {
    transform: scale(1.5);
}

/* TABLE */

table {
    margin-top: 1rem;
}

.table__row {
    padding-top: 2rem !important;
    cursor: pointer;
}

.table__row:hover {
    background-color: var(--DARK_BACKGROUND);
}

.table__cell--inactive {
    color: #9d9d9d !important;
}

/* Edit Side */

@media screen and (min-width: 576px) {
    .personal-checkbox {
        margin-top: 2.5rem;
        margin-left: 2.5rem;
    }
}

@media screen and (max-width: 576px) {
    .personal-checkbox {
        margin-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
}

.delete-button {
    display: flex;
    align-items: center !important;
}

/* SCROLL BAR */
::-webkit-scrollbar {
    background-color: #fff;
    width: 1.1rem
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
    background-color: #fff
}

::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #fff
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid #f4f4f4
}

/* WARNING MODAL */

.delete-symbol {
    transform: scale(1) translate(74%, -10%);
    max-width: 40% !important;
    padding-top: 2rem;
}

.delete-contact-button {
    width: 11rem !important;
    padding: 0.5rem 1rem 0.5rem 1rem !important;
}

.cancel-button {
    width: 11rem !important;
    padding: 0.5rem 1rem 0.5rem 1rem !important;
}

/* UTILITY CLASSES */

.access_denied {
    color: red !important;
    text-align: center !important;
}


.alert {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1.2rem;
    display: inline-block;
}

.error {
    color: var(--ERROR);
    font-size: 1.2rem;
}

.error a:hover,
.error a {
    color: var(--ERROR);
    text-decoration: underline;
}

.is-invalid {
    border: 1px solid var(--ERROR) !important;
}

/* Administration field styling */
.form-control[readonly]:not([plaintext]) {
    background-color: #fff;
    opacity: 1;
    cursor: default;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.form-control[readonly]:not([plaintext]):focus {
    box-shadow: none;
    border-color: #ced4da;  /* This is Bootstrap's default border color */
}